define("zvo-frontend/models/api-key", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('ds-error')]
  });

  var _default = _emberData.default.Model.extend(Validations, {
    name: _emberData.default.attr('string'),
    value: _emberData.default.attr('string')
  });

  _exports.default = _default;
});