define("zvo-frontend/routes/root/clients/entity/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    init: function init() {
      this._super();

      this.breadCrumb = {};
    },
    model: function model(params) {
      return this.store.findRecord('client/entity', params.id);
    },
    afterModel: function afterModel(model) {
      var client = {
        title: "".concat(model.name)
      };
      this.set('breadCrumb', client);
    }
  });

  _exports.default = _default;
});