define("zvo-frontend/controllers/root/users/show/logs", ["exports", "ember-math-helpers/helpers/mult", "moment"], function (_exports, _mult, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['from', 'to'],
    fromObserver: Ember.observer('fromDate', function () {
      if (this.fromDate) {
        this.set('from', (0, _moment.default)(this.fromDate).startOf('day').format('DD.MM.YYYY'));
      } else {
        this.set('from', null);
      }
    }),
    toObserver: Ember.observer('toDate', function () {
      if (this.toDate) {
        this.set('to', (0, _moment.default)(this.toDate).endOf('day').format('DD.MM.YYYY'));
      } else {
        this.set('to', null);
      }
    })
  });

  _exports.default = _default;
});