define("zvo-frontend/services/current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service(),
    load: function load() {
      var _this = this;

      if (this.get('session.isAuthenticated')) {
        return this.get('store').queryRecord('user', {
          me: true
        }).then(function (user) {
          _this.set('user', user);

          return user;
        });
      } else {
        return Ember.RSVP.resolve();
      }
    }
  });

  _exports.default = _default;
});