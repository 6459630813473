define("zvo-frontend/routes/root/assembly-shops/index", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, {
    perPage: 25,
    model: function model(params) {
      return this.findPaged('assembly-shop', params);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('shops', model.content.toArray());
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});