define("zvo-frontend/routes/root/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    init: function init() {
      this._super();

      this.breadCrumb = {
        title: 'Страница не найдена',
        linkable: false
      };
    }
  });

  _exports.default = _default;
});