define("zvo-frontend/components/attachment-pusher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['mb-2', 'col-12'],
    isIncluded: Ember.computed('attachments.@each', function () {
      return this.attachments && this.attachments.findBy('name', this.attachment.name) !== undefined;
    }),
    actions: {
      addAttachment: function addAttachment(attachment) {
        this.addAttachment(attachment);
      },
      removeAttachment: function removeAttachment(attachment) {
        this.removeAttachment(attachment);
      }
    }
  });

  _exports.default = _default;
});