define("zvo-frontend/initializers/ember-i18n-cp-validations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {// intentionally left blank to not break upgrade path
  }

  var _default = {
    name: 'ember-i18n-cp-validations',
    initialize: initialize
  };
  _exports.default = _default;
});