define("zvo-frontend/routes/root/users/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    init: function init() {
      this._super();

      this.breadCrumb = {
        title: 'Добавление'
      };
    },
    beforeModel: function beforeModel() {
      var result = this._super.apply(this, arguments);

      if (this.get('can').cannot('write user')) {
        return this.transitionTo('root');
      }

      return result;
    },
    model: function model() {
      return this.store.createRecord('user');
    },
    deactivate: function deactivate() {
      this.get('controller.model').rollbackAttributes();
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('rolesList', this.store.findAll('role'));
      controller.set('productionStages', this.store.findAll('productionStage'));
    }
  });

  _exports.default = _default;
});