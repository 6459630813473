define("zvo-frontend/templates/sign-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Nzs7yiLQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container h-100\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row h-100\"],[9],[0,\"\\n    \"],[7,\"form\"],[11,\"class\",\"col-md-4 col-sm-12 m-auto\"],[3,\"action\",[[22,0,[]],\"authenticate\"],[[\"on\"],[\"submit\"]]],[9],[0,\"\\n      \"],[7,\"h4\"],[11,\"class\",\"text-center pb-2\"],[9],[0,\"Аутентификация\"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"errorMessage\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"col-12 alert alert-danger text-center\"],[9],[0,\"\\n          \"],[1,[21,\"errorMessage\"],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n        \"],[1,[27,\"input\",null,[[\"id\",\"name\",\"class\",\"type\",\"placeholder\",\"value\"],[\"login-input\",\"login\",\"form-control\",\"text\",\"Логин\",[23,[\"login\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n        \"],[1,[27,\"input\",null,[[\"id\",\"name\",\"class\",\"type\",\"placeholder\",\"value\"],[\"login-password\",\"password\",\"form-control\",\"password\",\"Пароль\",[23,[\"password\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"id\",\"login-submit\"],[11,\"class\",\"btn btn-primary btn-block\"],[11,\"type\",\"submit\"],[9],[0,\"Войти\"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zvo-frontend/templates/sign-in.hbs"
    }
  });

  _exports.default = _default;
});