define("zvo-frontend/models/logs/work", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    task: _emberData.default.belongsTo('order/product/production-stage/task'),
    createdAt: _emberData.default.attr('string'),
    orderId: _emberData.default.attr('string'),
    productId: _emberData.default.attr('string'),
    productionStageId: _emberData.default.attr('string'),
    orderBaseId: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    user: _emberData.default.belongsTo('user'),
    cost: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    items: _emberData.default.hasMany('logs/work/item')
  });

  _exports.default = _default;
});