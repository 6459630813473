define("zvo-frontend/models/order", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    AASM_STATES: {
      'on_moderation': 'На модерации',
      'in_progress': 'В работе',
      'completed': 'Завершен',
      'archived': 'В архиве'
    },
    AASM_STATE_CLASSES: {
      'on_moderation': 'badge-danger',
      'in_progress': 'badge-warning',
      'completed': 'badge-success',
      'archived': 'badge-secondary'
    },
    aasmState: _emberData.default.attr('string'),
    baseId: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    not_standart: _emberData.default.attr('boolean'),
    not_standart_details: _emberData.default.attr('string'),
    managerComment: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('string'),
    updatedAt: _emberData.default.attr('string'),
    position: _emberData.default.attr('number'),
    plannedCompletionDate: _emberData.default.attr('string'),
    actualCompletionDate: _emberData.default.attr('string'),
    fav: _emberData.default.attr('boolean'),
    assemblyShop: _emberData.default.belongsTo('assembly-shop'),
    entity: _emberData.default.belongsTo('client/entity'),
    individual: _emberData.default.belongsTo('client/individual'),
    products: _emberData.default.hasMany('order/product', {
      async: false
    }),
    firstProduct: Ember.computed('products@.each', function () {
      return this.products.filterBy('type', 'default').firstObject;
    }),
    formattedAasmState: Ember.computed('aasmState', function () {
      return this.AASM_STATES[this.aasmState];
    }),
    aasmStateClass: Ember.computed('aasmState', function () {
      return this.AASM_STATE_CLASSES[this.aasmState];
    }),
    productNames: Ember.computed('products.@each', function () {
      var _this = this;

      var names = '';
      this.products.forEach(function (product, index) {
        var separator = '';

        if (index + 1 < _this.products.length) {
          separator = ', ';
        } else {
          separator = '';
        }

        names += product.name + separator;
      });
      return names;
    })
  });

  _exports.default = _default;
});