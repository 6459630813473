define("zvo-frontend/templates/components/attachment-pusher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tJCG7i4L",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isIncluded\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"class\",\"btn btn-success mr-1\"],[3,\"action\",[[22,0,[]],\"removeAttachment\",[23,[\"attachment\"]]]],[9],[7,\"i\"],[11,\"class\",\"fa fa-check\"],[11,\"aria-hidden\",\"true\"],[9],[10],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"class\",\"btn btn-primary mr-1\"],[3,\"action\",[[22,0,[]],\"addAttachment\",[23,[\"attachment\"]]]],[9],[7,\"i\"],[11,\"class\",\"fa fa-plus\"],[11,\"aria-hidden\",\"true\"],[9],[10],[10],[0,\"\\n\"]],\"parameters\":[]}],[1,[27,\"inc\",[[23,[\"index\"]],1],null],false],[0,\". \"],[1,[23,[\"attachment\",\"name\"]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zvo-frontend/templates/components/attachment-pusher.hbs"
    }
  });

  _exports.default = _default;
});