define("zvo-frontend/routes/root/orders/show/add-product", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      filter: {
        refreshModel: true
      }
    },
    init: function init() {
      this._super();

      this.breadCrumb = {
        title: 'Добавление продукта'
      };
    },
    beforeModel: function beforeModel() {
      var result = this._super.apply(this, arguments);

      if (this.get('can').cannot('write order')) {
        return this.transitionTo('root');
      }

      return result;
    },
    model: function model(params) {
      var q = {};
      q['not_hidden'] = true;

      if (Ember.isPresent(params.filter)) {
        q['name_or_base_id_matches'] = params.filter;
        delete params['filter'];
      }

      params['q'] = q;
      return this.store.query('product', params);
    },
    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments);

      controller.set('order', this.modelFor('root.orders.show'));

      if (transition.queryParams.filter) {
        controller.set('filterValue', transition.queryParams.filter);
      }
    }
  });

  _exports.default = _default;
});