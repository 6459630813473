define("zvo-frontend/routes/root/products/show/items/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    init: function init() {
      this._super();

      this.breadCrumb = {
        title: 'Редактирование'
      };
    },
    model: function model(params) {
      return this.store.findRecord('product/item', params.item_id);
    },
    deactivate: function deactivate() {
      this.controller.model.rollbackAttributes();
    }
  });

  _exports.default = _default;
});