define("zvo-frontend/translations/ru", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "roles": {
      "administrator": "Администратор",
      "client_delete": "Удаление",
      "client_read": "Чтение",
      "client_write": "Запись",
      "groups": {
        "client": "Клиенты",
        "order": "Заказы",
        "product": "Продукция",
        "production_stage": "Этапы производства",
        "user": "Пользователи"
      },
      "order_complete": "Завершение задачи",
      "order_delete": "Удаление",
      "order_read": "Чтение",
      "order_write": "Запись",
      "product_delete": "Удаление",
      "product_read": "Чтение",
      "product_write": "Запись",
      "production_stage_delete": "Удаление",
      "production_stage_read": "Чтение",
      "production_stage_write": "Запись",
      "super_administrator": "Владелец",
      "user_delete": "Удаление",
      "user_read": "Чтение",
      "user_write": "Запись"
    }
  };
  _exports.default = _default;
});