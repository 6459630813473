define("zvo-frontend/components/bread-crumbs", ["exports", "ember-crumbly/components/bread-crumbs"], function (_exports, _breadCrumbs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _breadCrumbs.default;
    }
  });
});