define("zvo-frontend/adapters/order", ["exports", "zvo-frontend/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    shouldReloadRecord: function shouldReloadRecord() {
      return true;
    }
  });

  _exports.default = _default;
});