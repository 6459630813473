define("zvo-frontend/templates/components/task-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0vywA5eM",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"icon\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"loading\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"fa fa-refresh fa-spin\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"i\"],[12,\"class\",[28,[\"fa fa-\",[21,\"icon\"]]]],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\" \\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[1,[21,\"title\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zvo-frontend/templates/components/task-button.hbs"
    }
  });

  _exports.default = _default;
});