define("zvo-frontend/routes/root/products/show/template/show/production-stages/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    init: function init() {
      this._super();

      this.breadCrumb = {};
    },
    model: function model(params) {
      return this.store.peekRecord('product/template/production-stage', params.production_stage_id);
    },
    afterModel: function afterModel(model) {
      var stage = {
        title: "\u042D\u0442\u0430\u043F ".concat(model.name)
      };
      this.set('breadCrumb', stage);
    }
  });

  _exports.default = _default;
});