define("zvo-frontend/routes/root/users/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    init: function init() {
      this._super();

      this.breadCrumb = {};
    },
    beforeModel: function beforeModel() {
      var result = this._super.apply(this, arguments);

      if (this.get('can').cannot('read user')) {
        return this.transitionTo('root');
      }

      return result;
    },
    model: function model(params) {
      return this.store.findRecord('user', params.id);
    },
    afterModel: function afterModel(model) {
      var user = {
        title: model.login
      };
      this.set('breadCrumb', user);
    }
  });

  _exports.default = _default;
});