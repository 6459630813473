define("zvo-frontend/routes/root/products/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    breadCrumb: {},
    model: function model(params) {
      return this.store.findRecord('product', params.id);
    },
    afterModel: function afterModel(model) {
      var product = {
        title: model.name
      };
      this.set('breadCrumb', product);
    }
  });

  _exports.default = _default;
});