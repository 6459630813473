define("zvo-frontend/routes/root/orders/edit", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    init: function init() {
      this._super();

      this.breadCrumb = {
        title: 'Редактирование заказа'
      };
    },
    beforeModel: function beforeModel() {
      var result = this._super.apply(this, arguments);

      if (this.get('can').cannot('write order')) {
        return this.transitionTo('root');
      }

      return result;
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        order: this.store.findRecord('order', params.id),
        individuals: this.store.findAll('client/individual'),
        entities: this.store.findAll('client/entity'),
        assemblyShops: this.store.findAll('assembly-shop')
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('plannedCompletionDate', (0, _moment.default)(model.order.plannedCompletionDate).startOf('day').toDate());
    }
  });

  _exports.default = _default;
});