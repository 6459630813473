define("zvo-frontend/models/logs/work/item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    baseId: _emberData.default.attr('string'),
    cost: _emberData.default.attr('number'),
    count: _emberData.default.attr('number'),
    availableCount: attr('number'),
    countObserver: Ember.observer('count', function () {
      if (this.count > this.availableCount) {
        this.set('count', this.availableCount);
      }

      if (!this.count || this.count < 0) {
        this.set('count', 0);
      }
    })
  });

  _exports.default = _default;
});