define("zvo-frontend/templates/root/products/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ykP0b3ZT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-12\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[0,\"Редактирование продукции\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-12 mt-3\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"form-group w-100\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-12 font-weight-bold\"],[9],[0,\"Название\"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-12\"],[9],[0,\"\\n          \"],[1,[27,\"input\",null,[[\"type\",\"class\",\"value\"],[\"text\",\"form-control\",[23,[\"model\",\"name\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[27,\"get\",[[27,\"get\",[[27,\"get\",[[27,\"get\",[[23,[\"model\"]],\"validations\"],null],\"attrs\"],null],\"name\"],null],\"isInvalid\"],null]],null,{\"statements\":[[0,\"            \"],[7,\"small\"],[11,\"id\",\"nameError\"],[11,\"class\",\"form-text text-danger\"],[9],[1,[27,\"get\",[[27,\"get\",[[27,\"get\",[[27,\"get\",[[23,[\"model\"]],\"validations\"],null],\"attrs\"],null],\"name\"],null],\"message\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"form-group w-100\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-12\"],[9],[0,\"\\n          \"],[5,\"task-button\",[[12,\"disabled\",[22,0,[\"save\",\"isRunning\"]]]],[[\"@icon\",\"@title\",\"@btnClass\",\"@action\"],[\"check\",\"Сохранить\",\"btn-primary\",[27,\"perform\",[[22,0,[\"save\"]]],null]]]],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zvo-frontend/templates/root/products/edit.hbs"
    }
  });

  _exports.default = _default;
});