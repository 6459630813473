define("zvo-frontend/routes/root/tasks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    init: function init() {
      this._super();

      this.breadCrumb = {
        title: 'Задачи'
      };
    }
  });

  _exports.default = _default;
});