define("zvo-frontend/routes/root/products/show/items/new", ["exports", "ember-uuid"], function (_exports, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    init: function init() {
      this._super();

      this.breadCrumb = {
        title: 'Добавление'
      };
    },
    model: function model(params) {
      var product = this.modelFor('root.products.show');
      return this.store.createRecord('product/item', {
        product: product,
        baseId: (0, _emberUuid.v1)()
      });
    },
    deactivate: function deactivate() {
      this.controller.model.rollbackAttributes();
    }
  });

  _exports.default = _default;
});