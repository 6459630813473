define("zvo-frontend/models/product", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Название не может быть пустым'
    }), (0, _emberCpValidations.validator)('ds-error')]
  });

  var _default = _emberData.default.Model.extend(Validations, {
    name: _emberData.default.attr('string'),
    cost: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    weight: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    baseId: _emberData.default.attr('string'),
    canBeDeletedFromUi: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    useTemplate: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    hidden: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    items: _emberData.default.hasMany('product/item', {
      async: false
    }),
    template: _emberData.default.belongsTo('product/template'),
    itemsTotalCost: Ember.computed('items@each', function () {
      var items = this.get('items');

      if (items.length > 0) {
        var cost = 0;
        items.forEach(function (item) {
          cost += item.get('cost') * item.get('count');
        });
        return cost;
      } else {
        return this.get('cost');
      }
    }),
    itemsTotalWeight: Ember.computed('items@each', function () {
      var items = this.get('items');

      if (items.length > 0) {
        var weight = 0;
        items.forEach(function (item) {
          weight += item.get('weight') * item.get('count');
        });
        return weight;
      } else {
        return this.get('weight');
      }
    }),
    drawingNum: Ember.computed('template.mainAssemblyTask.description', 'template.drawingNumManual', function () {
      var manual = this.get('template.drawingNumManual');

      if (manual) {
        return manual;
      }

      var mainAssemblyDrawing = this.get('template.mainAssemblyTask.description');
      return mainAssemblyDrawing || "-";
    })
  });

  _exports.default = _default;
});