define("zvo-frontend/routes/root/orders/show/products/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    title: 'Редактирование продукции',
    init: function init() {
      this._super();

      this.breadCrumb = {
        title: this.title
      };
    },
    beforeModel: function beforeModel() {
      var result = this._super.apply(this, arguments);

      if (this.get('can').cannot('write order')) {
        return this.transitionTo('root');
      }

      return result;
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        product: this.store.findRecord('order/product', params.product_id)
      });
    },
    afterModel: function afterModel(models) {
      models.product.startTrack();
    },
    setupController: function setupController(controller, models) {
      controller.set('product', models.product);
      controller.set('title', this.title);
    },
    deactivate: function deactivate() {
      this.get('controller').cancel();
    }
  });

  _exports.default = _default;
});