define("zvo-frontend/abilities/product", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    currentUser: Ember.inject.service(),
    user: Ember.computed.reads('currentUser.user').readOnly(),
    canRead: Ember.computed.reads('user.isAdministrator').readOnly(),
    canWrite: Ember.computed.reads('user.isAdministrator').readOnly(),
    canCreateTemplate: Ember.computed.reads('user.isAdministrator').readOnly()
  });

  _exports.default = _default;
});