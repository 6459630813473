define("zvo-frontend/templates/root/unauthorized", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LwWE1nMt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\"],[11,\"class\",\"text-center mt-5\"],[9],[0,\"Доступ запрещен\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zvo-frontend/templates/root/unauthorized.hbs"
    }
  });

  _exports.default = _default;
});