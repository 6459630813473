define("zvo-frontend/models/product/item", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    cost: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Укажите тариф'
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      gte: 0,
      message: 'Тариф должен быть положительным'
    }), (0, _emberCpValidations.validator)('ds-error')],
    weight: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      allowString: true,
      message: 'Укажите вес изделия'
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: 0,
      message: 'Вес должен быть положительным'
    }), (0, _emberCpValidations.validator)('ds-error')]
  });

  var _default = _emberData.default.Model.extend(Validations, {
    name: _emberData.default.attr('string'),
    cost: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    count: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    weight: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    baseId: _emberData.default.attr('string'),
    product: _emberData.default.belongsTo('product'),
    attachments: _emberData.default.hasMany('product/item/attachment')
  });

  _exports.default = _default;
});