define("zvo-frontend/routes/root/additional-components/show/template/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    init: function init() {
      this._super();

      this.breadCrumb = {
        title: 'Шаблон'
      };
    },
    model: function model() {
      var product = this.modelFor('root.additional-components.show');
      return product.template;
    }
  });

  _exports.default = _default;
});