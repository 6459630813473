define("zvo-frontend/components/product-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    stagesSorting: Object.freeze(['position:asc']),
    stages: Ember.computed.sort('product.productionStages', 'stagesSorting'),
    deletedStages: [],
    stagesLength: Ember.computed('product.productionStages', function () {
      return this.get('product.productionStages').length;
    }).readOnly(),
    actions: {
      submit: function submit() {
        this.onSubmit(this.deletedStages);
        this.deletedStages = [];
      },
      cancel: function cancel() {
        this.onCancel();
        this.deletedStages = [];
      },
      dragEnd: function dragEnd(_ref) {
        var sourceList = _ref.sourceList,
            sourceIndex = _ref.sourceIndex,
            targetList = _ref.targetList,
            targetIndex = _ref.targetIndex;
        if (sourceList === targetList && sourceIndex === targetIndex) return;
        var item = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, item);
        targetList.forEach(function (item, index) {
          item.set('position', index + 1);
        });
      },
      addProductionStage: function addProductionStage(productionStage) {
        var stage = this.store.createRecord('order/product/production-stage', {
          name: productionStage.name,
          position: this.stagesLength + 1
        });
        this.product.productionStages.pushObject(stage);
      },
      removeProductionStage: function removeProductionStage(stage) {
        if (stage.isNew) {
          stage.rollbackAttributes();
        } else {
          stage.deleteRecord();
          this.deletedStages.pushObject(stage);
        }

        this.product.productionStages.removeObject(stage);
        this.product.productionStages.forEach(function (item, index) {
          item.set('position', index + 1);
        });
      },
      loadProductionStages: function loadProductionStages() {
        var _this = this;

        this.set('productionStagesLoading', true);
        this.store.findAll('production-stage').then(function (stage) {
          _this.set('loadedProductionStages', stage);
        }).catch(function () {
          _this.get('notifications').error('Не удалось загрузить этапы производства.', {
            autoClear: true,
            clearDuration: 10000
          });
        }).finally(function () {
          _this.set('productionStagesLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});