define("zvo-frontend/routes/root/orders/show/products/show/production-stages/show/tasks/edit", ["exports", "ember-concurrency"], function (_exports, RSVP) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    title: 'Редактирование задачи',
    init: function init() {
      this._super();

      this.breadCrumb = {
        title: this.title
      };
    },
    model: function model(params) {
      return this.store.findRecord('order/product/production-stage/task', params.task_id);
    },
    setupController: function setupController(controller, model) {
      controller.set('title', this.title);
      controller.set('task', model);
      this.store.findAll('attachment').then(function (att) {
        return controller.set('attachments', att);
      });
    },
    afterModel: function afterModel(model) {
      model.startTrack();
    },
    deactivate: function deactivate() {
      this.controller.task.items.forEach(function (item) {
        item.rollbackAttributes();
      });
      this.controller.task.rollback();
    }
  });

  _exports.default = _default;
});