define("zvo-frontend/helpers/trim-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.trimString = trimString;
  _exports.default = void 0;

  function trimString(params
  /*, hash*/
  ) {
    return params[0].substring(0, params[1]);
  }

  var _default = Ember.Helper.helper(trimString);

  _exports.default = _default;
});