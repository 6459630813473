define("zvo-frontend/components/task-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    stagesStates: Ember.computed('task.productionStages.@each', function () {
      var _this = this;

      return this.get('task.template.productionStages').map(function (st) {
        return _this.get('task.productionStages').includes(st);
      });
    }),
    actions: {
      dragEnd: function dragEnd(_ref) {
        var sourceList = _ref.sourceList,
            sourceIndex = _ref.sourceIndex,
            targetList = _ref.targetList,
            targetIndex = _ref.targetIndex;
        if (sourceList === targetList && sourceIndex === targetIndex) return;
        var item = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, item); // targetList.forEach((item, index) => {
        //   item.set('position', index + 1);
        // });

        var template = this.get('template');
        template.content.save();
      },
      toggle: function toggle(task) {
        task.toggleProperty('collapsed');
      },
      deleteTask: function deleteTask(task) {
        var _this2 = this;

        if (confirm('Вы уверены что хотите удалить задачу?')) {
          task.destroyRecord().then(function () {
            _this2.get('notifications').success('Вы успешно удалили задачу из этапа!', {
              autoClear: true,
              clearDuration: 2000
            });
          }).catch(function (data) {
            if (data.code === 403) {
              _this2.get('notifications').error(data.error, {
                autoClear: true,
                clearDuration: 10000
              });
            } else {
              _this2.get('notifications').error('Что-то пошло не так.', {
                autoClear: true,
                clearDuration: 10000
              });
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});