define("zvo-frontend/routes/root/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    init: function init() {
      this._super();

      this.breadCrumb = {
        title: 'Управление доступом',
        linkable: false
      };
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.get('can').cannot('edit config')) {
        return this.transitionTo('root');
      } else {
        return this.transitionTo('root.config.api-keys');
      }
    }
  });

  _exports.default = _default;
});