define("zvo-frontend/routes/root/products/index", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, {
    perPage: 25,
    model: function model() {
      return this.findPaged('product', {
        q: {
          not_hidden: true,
          with_template: true,
          type_eq: "default"
        }
      });
    }
  });

  _exports.default = _default;
});