define("zvo-frontend/routes/root/products/show/template/show/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var product = this.modelFor('root.products.show');
      return product.template;
    }
  });

  _exports.default = _default;
});