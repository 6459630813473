define("zvo-frontend/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    login: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    secondName: _emberData.default.attr('string'),
    thirdName: _emberData.default.attr('string'),
    canReadOrder: _emberData.default.attr('boolean'),
    canWriteOrder: _emberData.default.attr('boolean'),
    canDeleteOrder: _emberData.default.attr('boolean'),
    canCompleteOrder: _emberData.default.attr('boolean'),
    canReadClient: _emberData.default.attr('boolean'),
    canWriteClient: _emberData.default.attr('boolean'),
    canDeleteClient: _emberData.default.attr('boolean'),
    canReadUser: _emberData.default.attr('boolean'),
    canWriteUser: _emberData.default.attr('boolean'),
    canDeleteUser: _emberData.default.attr('boolean'),
    canReadProductionStage: _emberData.default.attr('boolean'),
    canWriteProductionStage: _emberData.default.attr('boolean'),
    canDeleteProductionStage: _emberData.default.attr('boolean'),
    isSuperAdministrator: _emberData.default.attr('boolean'),
    isAdministrator: _emberData.default.attr('boolean'),
    password: _emberData.default.attr('string'),
    roles: _emberData.default.hasMany('role'),
    tasks: _emberData.default.hasMany('order/product/production-stage/task'),
    productionStages: _emberData.default.hasMany('production-stage')
  });

  _exports.default = _default;
});