define("zvo-frontend/routes/root", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    setupController: function setupController(controller, _) {
      this.store.findAll('assembly-shop').then(function (shops) {
        controller.set('assembly-shops', shops);
      });
    }
  });

  _exports.default = _default;
});