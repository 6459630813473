define("zvo-frontend/models/order/product/production-stage", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    AASM_STATE_CLASSES: {
      'not_using': 'btn p-2 btn-secondary mr-1',
      'waiting': 'btn p-2 btn-danger mr-1',
      'working': 'btn p-2 btn-warning mr-1',
      'completed': 'btn p-2 btn-success mr-1'
    },
    name: _emberData.default.attr('string'),
    position: _emberData.default.attr('number'),
    aasmState: _emberData.default.attr('string'),
    completedPercentage: _emberData.default.attr('number'),
    product: _emberData.default.belongsTo('order/product'),
    tasks: _emberData.default.hasMany('order/product/production-stage/task', {
      async: false
    }),
    aasmStateClass: Ember.computed('aasmState', function () {
      return this.AASM_STATE_CLASSES[this.aasmState];
    }),
    hasNoTasks: Ember.computed('tasks.@each', function () {
      return this.tasks.length === 0;
    })
  });

  _exports.default = _default;
});