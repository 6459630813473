define("zvo-frontend/services/ajax", ["exports", "ember-ajax/services/ajax", "zvo-frontend/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    host: "".concat(_environment.default.protocol, "://").concat(_environment.default.host),
    contentType: 'application/json; charset=utf-8',
    headers: Ember.computed('session.data.authenticated.token', {
      get: function get() {
        var headers = {};
        var token = this.get('session.data.authenticated.token');

        if (token) {
          headers['Authorization'] = "Bearer ".concat(token, "\"");
        }

        return headers;
      }
    })
  });

  _exports.default = _default;
});