define("zvo-frontend/routes/root/additional-components/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    init: function init() {
      this._super();

      this.breadCrumb = {
        title: 'Добавление'
      };
    },
    beforeModel: function beforeModel() {
      var result = this._super.apply(this, arguments);

      if (this.get('can').cannot('write product')) {
        return this.transitionTo('root');
      }

      return result;
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('selectedProduct', null);
    }
  });

  _exports.default = _default;
});