define("zvo-frontend/adapters/application", ["exports", "active-model-adapter", "ember-simple-auth/mixins/data-adapter-mixin", "zvo-frontend/config/environment"], function (_exports, _activeModelAdapter, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.default.extend(_dataAdapterMixin.default, {
    host: "".concat(_environment.default.protocol, "://").concat(_environment.default.host),
    namespace: 'api',
    headers: Ember.computed(function () {
      return {
        'Accept': 'application/json'
      };
    }),
    authorize: function authorize(xhr) {
      var _this$get = this.get('session.data.authenticated'),
          token = _this$get.token;

      var authData = "Bearer ".concat(token, "\"");
      xhr.setRequestHeader('Authorization', authData);
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      if (403 === status || 404 === status || payload && payload.error && status >= 400) {
        return payload;
      }

      return this._super(status, headers, payload, requestData);
    }
  });

  _exports.default = _default;
});