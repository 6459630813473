define("zvo-frontend/templates/components/orders/page-archiver", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LHASSIb0",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bs-button-group\",null,[[\"class\"],[\"row\"]],{\"statements\":[[4,\"bs-button\",null,[[\"class\",\"type\",\"onClick\"],[\"col\",\"secondary\",[27,\"action\",[[22,0,[]],[27,\"perform\",[[23,[\"archivePage\"]],[23,[\"orders\"]]],null]],null]]],{\"statements\":[[0,\"    Архивировать страницу\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zvo-frontend/templates/components/orders/page-archiver.hbs"
    }
  });

  _exports.default = _default;
});