define("zvo-frontend/templates/root/assembly-shops/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZZbOXu7V",
    "block": "{\"symbols\":[\"shop\",\"index\"],\"statements\":[[7,\"div\"],[11,\"class\",\"col-12 mt-3\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"content\",\"length\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"row mb-2 border-bottom\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-1 font-weight-bold\"],[9],[0,\"#\"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-8 font-weight-bold\"],[9],[0,\"Название\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"drag-sort-list\",null,[[\"items\",\"class\",\"childClass\",\"dragEndAction\"],[[23,[\"shops\"]],\"row\",\"col-12 mb-2 sort-selector\",[27,\"action\",[[22,0,[]],\"dragEnd\"],null]]],{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-1 pt-2\"],[9],[1,[27,\"inc\",[[22,2,[]],1],null],false],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-8 pt-2\"],[9],[1,[22,1,[\"name\"]],false],[10],[0,\"\\n\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"p\"],[9],[0,\"Данные отсутствуют\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"model\",\"content\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"col-12 mt-3\"],[9],[0,\"\\n    \"],[1,[27,\"page-numbers\",null,[[\"content\"],[[23,[\"model\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zvo-frontend/templates/root/assembly-shops/index.hbs"
    }
  });

  _exports.default = _default;
});