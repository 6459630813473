define("zvo-frontend/models/attachment", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Укажите название'
    }), (0, _emberCpValidations.validator)('ds-error')],
    file: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Выберите файл'
    }), (0, _emberCpValidations.validator)('ds-error')]
  });

  var _default = _emberData.default.Model.extend(Validations, {
    name: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    png_url: _emberData.default.attr('string'),
    file: _emberData.default.attr('file'),
    ext: Ember.computed('url', function () {
      var url = this.url || '';
      var idx = url.lastIndexOf('.');

      if (idx > 0) {
        return url.substr(idx + 1).toUpperCase();
      } else {
        return "FILE";
      }
    })
  });

  _exports.default = _default;
});