define("zvo-frontend/templates/components/file-choser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jb2GTWVO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"d-none\"],[9],[0,\"\\n  \"],[7,\"input\"],[11,\"class\",\"file-input\"],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"setFile\"],null]],[11,\"type\",\"file\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"item\",\"attachmentUrl\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"col\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"title\",\"Удалить файл\"],[11,\"class\",\"btn btn-danger\"],[3,\"action\",[[22,0,[]],\"clearFile\"]],[9],[7,\"i\"],[11,\"class\",\"fa fa-file\"],[11,\"aria-hidden\",\"true\"],[9],[10],[7,\"i\"],[11,\"class\",\"fa fa-trash\"],[11,\"aria-hidden\",\"true\"],[9],[10],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col\"],[9],[0,\"\\n      \"],[7,\"a\"],[12,\"href\",[23,[\"item\",\"attachmentUrl\"]]],[11,\"target\",\"_blank\"],[9],[0,\"Ссылка\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"col\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"title\",\"Прикрепить файл\"],[11,\"class\",\"btn btn-primary\"],[3,\"action\",[[22,0,[]],\"openFileChoser\"]],[9],[7,\"i\"],[11,\"class\",\"fa fa-file\"],[11,\"aria-hidden\",\"true\"],[9],[10],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zvo-frontend/templates/components/file-choser.hbs"
    }
  });

  _exports.default = _default;
});