define("zvo-frontend/components/roles-component", ["exports", "ember-group-by"], function (_exports, _emberGroupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['row'],
    groups: (0, _emberGroupBy.default)('rolesList', 'group'),
    actions: {
      toggleRole: function toggleRole(role) {
        if (this.selectedRoles.indexOf(role) !== -1) {
          this.get('selectedRoles').removeObject(role);
        } else {
          this.get('selectedRoles').pushObject(role);
        }
      }
    }
  });

  _exports.default = _default;
});