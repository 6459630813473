define("zvo-frontend/templates/root/clients", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yz1hLaOz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-12 border-bottom pb-2 mb-2\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[0,\"Клиенты\"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-6 offset-md-3 col-12\"],[9],[0,\"\\n    \"],[7,\"nav\"],[11,\"class\",\"nav nav-pills nav-fill\"],[9],[0,\"\\n      \"],[4,\"link-to\",[\"root.clients.entity\"],[[\"class\"],[\"nav-item nav-link\"]],{\"statements\":[[0,\"Юридические лица / ИП\"]],\"parameters\":[]},null],[0,\"\\n      \"],[4,\"link-to\",[\"root.clients.individual\"],[[\"class\"],[\"nav-item nav-link\"]],{\"statements\":[[0,\"Физические лица\"]],\"parameters\":[]},null],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-12\"],[9],[0,\"\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zvo-frontend/templates/root/clients.hbs"
    }
  });

  _exports.default = _default;
});