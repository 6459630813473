define("zvo-frontend/routes/root/clients/individual/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    init: function init() {
      this._super();

      this.breadCrumb = {
        title: 'Редактирование'
      };
    },
    beforeModel: function beforeModel() {
      var result = this._super.apply(this, arguments);

      if (this.get('can').cannot('write client')) {
        return this.transitionTo('root');
      }

      return result;
    },
    model: function model(params) {
      return this.store.findRecord('client/individual', params.id);
    },
    deactivate: function deactivate() {
      this.get('controller.model').rollbackAttributes();
    }
  });

  _exports.default = _default;
});