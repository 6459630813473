define("zvo-frontend/abilities/production-stage", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    currentUser: Ember.inject.service(),
    user: Ember.computed.reads('currentUser.user').readOnly(),
    canRead: Ember.computed.reads('user.canReadProductionStage').readOnly(),
    canWrite: Ember.computed.reads('user.canWriteProductionStage').readOnly(),
    canDelete: Ember.computed.reads('user.canDeleteProductionStage').readOnly()
  });

  _exports.default = _default;
});