define("zvo-frontend/routes/root/orders/show/fast-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    title: 'Добавление нестандартного продукта',
    init: function init() {
      this._super();

      this.breadCrumb = {
        title: this.title
      };
    },
    model: function model(params) {
      var order = this.modelFor('root.orders.show');
      return this.store.createRecord('order/product', {
        order: order,
        name: params.name
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('product', model);
      controller.set('order', model.order);
      controller.set('title', this.title);
    },
    deactivate: function deactivate() {
      this.controller.set('proudctionStages', []);
      this.controller.set('selectedProduct', undefined);
      this.controller.product.rollbackAttributes();
    }
  });

  _exports.default = _default;
});