define("zvo-frontend/models/product/item/attachment", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Укажите название'
    }), (0, _emberCpValidations.validator)('ds-error')],
    file: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Выберите файл'
    }), (0, _emberCpValidations.validator)('ds-error')],
    productionStages: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Выберите этапы производства'
    }), (0, _emberCpValidations.validator)('ds-error')]
  });

  var _default = _emberData.default.Model.extend(Validations, {
    name: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    file: _emberData.default.attr('file'),
    item: _emberData.default.belongsTo('product/item'),
    productionStages: _emberData.default.hasMany('productionStage', {
      async: false
    })
  });

  _exports.default = _default;
});