define("zvo-frontend/models/product/template/task", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    // drawing number
    position: _emberData.default.attr('number'),
    required: _emberData.default.attr('boolean'),
    cost: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    collapsed: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    items: _emberData.default.hasMany('product/template/task/item'),
    attachments: _emberData.default.hasMany('product/template/task/attachment'),
    productionStages: _emberData.default.hasMany('product/template/production_stage'),
    template: _emberData.default.belongsTo('product/template') // productionStages: DS.belongsTo('product/template/production-stage'),

  });

  _exports.default = _default;
});