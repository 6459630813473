define("zvo-frontend/routes/root/products/show/template/show/production-stages/show/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    init: function init() {
      this._super();

      this.breadCrumb = {};
    },
    model: function model(params) {
      return this.modelFor('root.products.show.template.show.production-stages.show');
    },
    afterModel: function afterModel(model) {
      var stage = {
        title: "\u042D\u0442\u0430\u043F ".concat(model.name)
      };
      this.set('breadCrumb', stage);
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      var product = this.modelFor('root.products.show');
      controller.set('template', product.get('template'));
      controller.set('product', product);
      controller.set('task', null);
    }
  });

  _exports.default = _default;
});