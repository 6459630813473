define("zvo-frontend/templates/root/orders/show/products/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pk+y40EF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-12\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"product-edit\",null,[[\"product\",\"onSubmit\",\"onCancel\"],[[23,[\"product\"]],[27,\"perform\",[[22,0,[\"save\"]]],null],[27,\"action\",[[22,0,[]],[22,0,[\"cancel\"]]],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zvo-frontend/templates/root/orders/show/products/edit.hbs"
    }
  });

  _exports.default = _default;
});