define("zvo-frontend/routes/root/products/show/template/show/tasks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    breadCrumb: null
  });

  _exports.default = _default;
});