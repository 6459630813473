define("zvo-frontend/models/common/item-base", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.defaultName = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Название не может быть пустым'
    }), (0, _emberCpValidations.validator)('ds-error')]
  });
  var defaultName = "Компонент";
  _exports.defaultName = defaultName;

  var _default = _emberData.default.Model.extend(Validations, {
    name: _emberData.default.attr('string', {
      defaultValue: defaultName
    }),
    cost: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    count: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    attachmentUrl: _emberData.default.attr('string'),
    baseId: _emberData.default.attr('string')
  });

  _exports.default = _default;
});