define("zvo-frontend/controllers/sign-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var credentials = this.getProperties('login', 'password'),
            authenticator = 'authenticator:jwt';
        this.get('session').authenticate(authenticator, credentials).then(function () {
          _this.get('notifications').success('Вы успешно вошли в аккаунт!', {
            autoClear: true,
            clearDuration: 2000
          });
        }).catch(function (reason) {
          _this.set('errorMessage', reason.payload.error || reason);
        });
      }
    }
  });

  _exports.default = _default;
});