define("zvo-frontend/models/client/individual", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    firstName: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Имя не может быть пустым'
    }), (0, _emberCpValidations.validator)('ds-error')],
    secondName: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Фамилия не может быть пустой'
    }), (0, _emberCpValidations.validator)('ds-error')]
  });

  var _default = _emberData.default.Model.extend(Validations, {
    firstName: _emberData.default.attr('string'),
    secondName: _emberData.default.attr('string'),
    thirdName: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    mobile: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string')
  });

  _exports.default = _default;
});