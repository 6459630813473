define("zvo-frontend/routes/root/orders/show/products/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    init: function init() {
      this._super();

      this.breadCrumb = {};
    },
    model: function model(params) {
      return this.store.peekRecord('order/product', params.product_id);
    },
    afterModel: function afterModel(model) {
      var product = {
        title: model.name
      };
      this.set('breadCrumb', product);
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('order', this.modelFor('root.orders.show'));
    }
  });

  _exports.default = _default;
});