define("zvo-frontend/components/orders/t-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * expecting index, order, openCommentModal action
   */
  var _default = Ember.Component.extend({
    tagName: ''
  });

  _exports.default = _default;
});