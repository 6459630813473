define("zvo-frontend/routes/root/products/show/items/show/attachments/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    breadCrumb: {
      title: 'Добавление'
    },
    model: function model() {
      return this.store.createRecord('product/item/attachment', {
        item: this.modelFor('root.products.show.items.show')
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('productionStages', this.store.findAll('productionStage'));
    },
    deactivate: function deactivate() {
      this.controller.model.rollbackAttributes();
    }
  });

  _exports.default = _default;
});