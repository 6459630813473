define("zvo-frontend/templates/root/products/show/template/show/tasks/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1nqMVQt0",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row border-bottom mb-2 pb-2\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-12\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"task-form\",null,[[\"task\",\"attachments\",\"onSuccess\"],[[23,[\"task\"]],[23,[\"attachments\"]],[27,\"action\",[[22,0,[]],\"successTransit\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zvo-frontend/templates/root/products/show/template/show/tasks/edit.hbs"
    }
  });

  _exports.default = _default;
});