define("zvo-frontend/components/task-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: 'btn',
    classNameBindings: ['btnClass'],
    attributeBindings: ['disabled'],
    disabled: false,
    btnClass: Ember.computed('type', function () {
      return "btn-".concat(this.type);
    }),
    click: function click() {
      this.sendAction('action');
    }
  });

  _exports.default = _default;
});