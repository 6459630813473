define("zvo-frontend/initializers/inject-cable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;

  function initialize(application) {
    application.inject('controller', 'websockets', 'service:cable');
    application.inject('route', 'websockets', 'service:cable');
  }
});