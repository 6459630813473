define("zvo-frontend/models/order/product", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Название не может быть пустым'
    }), (0, _emberCpValidations.validator)('ds-error')]
  });

  var _default = _emberData.default.Model.extend(Validations, {
    name: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    canBeDeletedFromUi: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    order: _emberData.default.belongsTo('order'),
    items: _emberData.default.hasMany('order/product/item', {
      async: false
    }),
    productionStages: _emberData.default.hasMany('order/product/productionStage', {
      async: false
    }),
    itemsTotalCost: Ember.computed('items@each', function () {
      var items = this.get('items');

      if (items.length > 0) {
        var cost = 0;
        items.forEach(function (item) {
          cost += item.get('cost') * item.get('count');
        });
        return cost;
      } else {
        return this.get('cost');
      }
    }),
    itemsTotalWeight: Ember.computed('items@each', function () {
      var items = this.get('items');

      if (items.length > 0) {
        var weight = 0;
        items.forEach(function (item) {
          weight += item.get('weight') * item.get('count');
        });
        return weight;
      } else {
        return this.get('weight');
      }
    }),
    hasNoTasks: Ember.computed('productionStages.@each.hasNoTasks', function () {
      var ps = this.get('productionStages');
      return ps.every(function (stage) {
        return stage.hasNoTasks;
      });
    })
  });

  _exports.default = _default;
});