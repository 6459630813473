define("zvo-frontend/routes/root/orders/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    init: function init() {
      this._super();

      this.breadCrumb = {};
    },
    model: function model(params) {
      return this.store.findRecord('order', params.id);
    },
    afterModel: function afterModel(model) {
      var order = {
        title: "\u0417\u0430\u043A\u0430\u0437 #".concat(model.baseId)
      };
      this.set('breadCrumb', order);
    }
  });

  _exports.default = _default;
});