define("zvo-frontend/routes/root/additional-components/show/template/show/production-stages/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      return this.transitionTo('root.additional-components.show.template.show');
    }
  });

  _exports.default = _default;
});