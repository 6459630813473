define("zvo-frontend/serializers/user", ["exports", "ember-data", "active-model-adapter"], function (_exports, _emberData, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      roles: {
        serialize: 'ids',
        deserialize: 'ids'
      },
      productionStages: {
        serialize: 'ids',
        deserialize: 'ids'
      }
    }
  });

  _exports.default = _default;
});