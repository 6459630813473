define("zvo-frontend/routes/root/products/show/items/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    init: function init() {
      this._super();

      this.breadCrumb = {};
    },
    model: function model(params) {
      return this.store.findRecord('product/item', params.item_id);
    },
    afterModel: function afterModel(model) {
      var item = {
        title: "".concat(model.name)
      };
      this.set('breadCrumb', item);
    }
  });

  _exports.default = _default;
});