define("zvo-frontend/routes/root/orders/index", ["exports", "@babel/runtime/helpers/esm/objectSpread", "ember-cli-pagination/remote/route-mixin", "zvo-frontend/config/environment", "moment"], function (_exports, _objectSpread2, _routeMixin, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, {
    page: 1,
    perPage: 10,
    queryParams: {
      page: {
        refreshModel: true
      },
      sorts: {
        refreshModel: true
      },
      assemblyShop: {
        refreshModel: true
      },
      idFilter: {
        refreshModel: true
      },
      typeFilter: {
        refreshModel: true
      },
      plannedCompletionDateFilter: {
        refreshModel: true
      },
      actualCompletionDateFilter: {
        refreshModel: true
      },
      mode: {
        refreshModel: true
      }
    },
    assemblyShops: null,
    inProgress: null,
    beforeModel: function beforeModel() {
      var _this = this;

      return this.store.findAll('assembly-shop').then(function (shops) {
        _this.set('assemblyShops', shops);
      });
    },
    model: function model(params) {
      var _this2 = this;

      var q = {};

      if (params.mode === 'archived') {
        q['aasm_state_eq'] = 'archived';
      } else {
        q['aasm_state_not_eq'] = 'archived';
      }

      delete params['mode'];

      if (Ember.isPresent(params.idFilter)) {
        q['base_id_matches'] = params.idFilter;
      }

      if (Ember.isPresent(params.typeFilter)) {
        q['type_matches'] = params.typeFilter;
      }

      if (Ember.isPresent(params.plannedCompletionDateFilter)) {
        q['planned_completion_date_eq'] = (0, _moment.default)(params.plannedCompletionDateFilter).startOf('day').format().split("T")[0];
      }

      if (Ember.isPresent(params.actualCompletionDateFilter)) {
        q['actual_completion_date_eq'] = (0, _moment.default)(params.actualCompletionDateFilter).startOf('day').format().split("T")[0];
      }

      if (Ember.isPresent(params.sorts)) {
        q['sorts'] = params.sorts;
      }

      var assemblyShopId;

      if (Ember.isPresent(params.assemblyShop)) {
        assemblyShopId = this.get('assemblyShops').find(function (s) {
          return s.name === params.assemblyShop;
        }).id;
      }

      params['q'] = q;
      delete params['idFilter'];
      delete params['typeFilter'];
      delete params['plannedCompletionDateFilter'];
      delete params['actualCompletionDateFilter'];
      delete params['sorts'];
      delete params['assemblyShop'];

      if (assemblyShopId) {
        q['assembly_shop_id_eq'] = assemblyShopId;
      }

      return this.store.query('order', {
        q: (0, _objectSpread2.default)({}, q, {
          aasm_state_eq: 'in_progress',
          sorts: 'position'
        })
      }).then(function (inProgress) {
        _this2.set('inProgress', inProgress);

        return _this2.findPaged('order', params);
      });
    },
    resetController: function resetController(controller, isExiting, transition) {
      var sorts = controller.get('sorts') || controller.get('defaultSorts');
      controller.set('sorts', sorts);
    },
    setupController: function setupController(controller, model, transition) {
      var _this3 = this;

      this._super.apply(this, arguments);

      var orders = model.content.toArray();
      controller.set('orders', orders);
      controller.set('inProgress', this.get('inProgress'));

      if (transition.queryParams.sorts) {
        controller.set('sorts', transition.queryParams.sorts || 'created_at desc');
      }

      if (transition.queryParams.idFilter) {
        controller.set('idFilter', transition.queryParams.idFilter);
      }

      if (transition.queryParams.typeFilter) {
        controller.set('typeFilter', transition.queryParams.typeFilter);
      }

      if (transition.queryParams.actualCompletionDateFilter) {
        controller.set('actualCompletionDate', (0, _moment.default)(transition.queryParams.actualCompletionDateFilter)._d);
      }

      if (transition.queryParams.plannedCompletionDateFilter) {
        controller.set('plannedCompletionDate', (0, _moment.default)(transition.queryParams.plannedCompletionDateFilter)._d);
      }

      var token = this.get('session.data.authenticated.token');
      this.consumer = this.get('websockets').createConsumer("".concat(_environment.default.wsprotocol, "://").concat(_environment.default.host, "/cable?token=").concat(token));
      this.subscription = this.consumer.subscriptions.create({
        channel: 'OrdersChannel'
      }, {
        received: function received(data) {
          switch (data.status) {
            case 'created':
              _this3.refresh();

              _this3.get('notifications').info("\u0414\u043E\u0431\u0430\u0432\u043B\u0435\u043D \u043D\u043E\u0432\u044B\u0439 \u0437\u0430\u043A\u0430\u0437 #".concat(data.id, "!"));

              break;

            case 'destroyed':
              _this3.refresh();

              _this3.get('notifications').info("\u0417\u0430\u043A\u0430\u0437 #".concat(data.id, " \u0443\u0434\u0430\u043B\u0435\u043D!"));

              break;

            default:
              _this3.store.pushPayload(data.data);

              _this3.get('notifications').info("\u0417\u0430\u043A\u0430\u0437 #".concat(data.id, " \u043E\u0431\u043D\u043E\u0432\u043B\u0435\u043D!"));

              break;
          }
        }
      });
    },
    deactivate: function deactivate() {
      this.consumer.subscriptions.remove(this.subscription);
    },
    actions: {
      refreshRoute: function refreshRoute() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});