define("zvo-frontend/models/product/template", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    changeTracker: {
      auto: true
    },
    product: _emberData.default.belongsTo('product'),
    productionStages: _emberData.default.hasMany('product/template/production-stage'),
    tasks: _emberData.default.hasMany('product/template/task'),
    drawingNumManual: _emberData.default.attr('string'),
    mainAssemblyTask: _emberData.default.belongsTo('product/template/task', {
      inverse: null
    })
  });

  _exports.default = _default;
});