define("zvo-frontend/models/order/product/production-stage/task/item", ["exports", "ember-data", "zvo-frontend/models/common/item-base"], function (_exports, _emberData, _itemBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _itemBase.default.extend({
    completedCount: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    countObserver: Ember.observer('count', function () {
      if (!this.count || this.count < 0) {
        this.set('count', 0);
      }
    })
  });

  _exports.default = _default;
});