define("zvo-frontend/components/production-stage-pusher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    isIncluded: Ember.computed('stages.@each', function () {
      if (!this.stages) {
        return false;
      }

      return this.stages.findBy('name', this.productionStage.name) !== undefined;
    }),
    actions: {
      addProductionStage: function addProductionStage(stage) {
        this.addProductionStage(stage);
      }
    }
  });

  _exports.default = _default;
});