define("zvo-frontend/controllers/root/assembly-shops/index", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      dragEnd: function dragEnd(_ref) {
        var sourceList = _ref.sourceList,
            sourceIndex = _ref.sourceIndex,
            targetList = _ref.targetList,
            targetIndex = _ref.targetIndex;
        if (sourceList === targetList && sourceIndex === targetIndex) return;
        var item = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, item);
        targetList.forEach(function (item, index) {
          item.set('position', index + 1);
          item.save();
        });
      }
    }
  });

  _exports.default = _default;
});