define("zvo-frontend/routes/root/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    init: function init() {
      this._super();

      this.breadCrumb = {
        title: 'Главная',
        linkable: false
      };
    },
    beforeModel: function beforeModel() {
      var _this = this;

      this.get('currentUser').load().then(function () {
        if (_this.currentUser.user.isAdministrator) {
          _this.transitionTo('root.orders');
        } else {
          _this.transitionTo('root.tasks');
        }
      });
    }
  });

  _exports.default = _default;
});