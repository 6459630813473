define("zvo-frontend/controllers/root/products/show/template/show/tasks/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      successTransit: function successTransit() {
        this.transitionToRoute('root.products.show.template.show.production-stages.show');
      }
    }
  });

  _exports.default = _default;
});