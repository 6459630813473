define("zvo-frontend/helpers/i18n-compile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.i18nCompile = i18nCompile;
  _exports.default = void 0;

  function i18nCompile(params
  /*, hash*/
  ) {
    return "".concat(params[0], ".").concat(params[1]);
  }

  var _default = Ember.Helper.helper(i18nCompile);

  _exports.default = _default;
});