define("zvo-frontend/router", ["exports", "zvo-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('root', {
      path: '/'
    }, function () {
      this.route('clients', function () {
        this.route('individual', function () {
          this.route('new');
          this.route('edit', {
            path: '/:id/edit'
          });
          this.route('show', {
            path: '/:id'
          });
        });
        this.route('entity', function () {
          this.route('new');
          this.route('edit', {
            path: '/:id/edit'
          });
          this.route('show', {
            path: '/:id'
          });
        });
      });
      this.route('production-stages', function () {});
      this.route('users', function () {
        this.route('edit', {
          path: '/:id/edit'
        });
        this.route('show', {
          path: '/:id'
        }, function () {
          this.route('logs');
        });
        this.route('new');
      });
      this.route('products', function () {
        this.route('show', {
          path: '/:id'
        }, function () {
          this.route('index', {
            path: '/'
          });
          this.route('items', {
            path: '/items'
          }, function () {
            this.route('edit', {
              path: '/:item_id/edit'
            });
            this.route('show', {
              path: '/:item_id'
            }, function () {
              this.route('attachments', function () {
                this.route('new');
                this.route('edit', {
                  path: '/:attachment_id/edit'
                });
              });
            });
            this.route('new');
          });
          this.route('template', function () {
            this.route('show', {
              path: '/'
            }, function () {
              this.route('production-stages', function () {
                this.route('show', {
                  path: '/:production_stage_id'
                }, function () {
                  this.route('index', {
                    path: '/'
                  });
                });
              });
              this.route('tasks', function () {
                this.route('edit', {
                  path: '/:task_id/edit'
                });
              });
            });
          });
        });
        this.route('new');
        this.route('edit', {
          path: '/:id/edit'
        });
      });
      this.route('orders', function () {
        this.route('show', {
          path: '/:id'
        }, function () {
          this.route('index', {
            path: '/'
          });
          this.route('add-product');
          this.route('products', function () {
            this.route('show', {
              path: '/:product_id'
            }, function () {
              this.route('production-stages', function () {
                this.route('show', {
                  path: '/:production_stage_id'
                }, function () {
                  this.route('index', {
                    path: '/'
                  });
                  this.route('tasks', function () {
                    this.route('edit', {
                      path: '/:task_id/edit'
                    });
                  });
                });
              });
            });
            this.route('edit', {
              path: '/:product_id/edit'
            });
          });
          this.route('fast-add');
        });
        this.route('edit', {
          path: '/:id/edit'
        });
        this.route('new');
      });
      this.route('config', function () {
        this.route('api-keys');
      });
      this.route('unauthorized');
      this.route('not-found', {
        path: '/*path'
      });
      this.route('tasks', function () {});
      this.route('attachments', function () {
        this.route('new');
        this.route('edit', {
          path: '/:id/edit'
        });
      });
      this.route('additional-components', function () {
        this.route('new');
        this.route('show', {
          path: '/:id'
        }, function () {
          this.route('template', function () {
            this.route('show', {
              path: '/'
            }, function () {
              this.route('production-stages', function () {
                this.route('show', {
                  path: '/:stage_id'
                });
              });
            });
            this.route('edit', {
              path: '/:product_id'
            });
          });
        });
      });
      this.route('assembly-shops', function () {});
    });
    this.route('sign-in');
  });
  var _default = Router;
  _exports.default = _default;
});