define("zvo-frontend/components/file-choser", ["exports", "ember-uuid", "zvo-frontend/models/common/item-base"], function (_exports, _emberUuid, _itemBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    actions: {
      openFileChoser: function openFileChoser() {
        this.element.getElementsByClassName("file-input")[0].click();
      },
      setFile: function setFile(e) {
        var _this = this;

        var file = e.target.files[0];
        var attachment = this.store.createRecord('attachment', {
          name: file.name + " (".concat((0, _emberUuid.v1)(), ")"),
          file: file
        });
        attachment.save().then(function (attach) {
          _this.set('item.attachmentUrl', attach.url);

          if (!_this.get('item.name') || _this.get('name') === _itemBase.defaultName) {
            _this.set('item.name', file.name);
          }
        });
      },
      clearFile: function clearFile() {
        var fileInput = this.element.getElementsByClassName("file-input")[0];
        var file = fileInput.files[0];

        if (file && this.get('item.name') === file.name) {
          this.set('item.name', null);
        }

        this.set('item.attachmentUrl', null);
        fileInput.value = null;
      }
    }
  });

  _exports.default = _default;
});