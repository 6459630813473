define("zvo-frontend/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "zvo-frontend/mixins/progress"], function (_exports, _applicationRouteMixin, _progress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _progress.default, {
    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionAuthenticated: function sessionAuthenticated() {
      this._super.apply(this, arguments);

      this._loadCurrentUser();
    },
    _loadCurrentUser: function _loadCurrentUser() {
      var _this = this;

      return this.get('currentUser').load().catch(function () {
        return _this.get('session').invalidate();
      });
    },
    beforeModel: function beforeModel() {
      this.get('intl').setLocale('ru');
      return this._loadCurrentUser();
    },
    actions: {
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      },
      error: function error(_error) {
        if (_error.status === 404) {
          this.intermediateTransitionTo('root.not-found', null);
        }

        if (_error.status === 403) {
          this.intermediateTransitionTo('root.unauthorized');
        }
      }
    }
  });

  _exports.default = _default;
});