define("zvo-frontend/controllers/root/orders/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['idFilter', 'typeFilter', 'plannedCompletionDateFilter', 'actualCompletionDateFilter', 'page', 'sorts', 'assemblyShop'],
    page: 1,
    sortColumn: Ember.computed('sorts', function () {
      return (this.sorts || "").split(' ')[0] || "created_at";
    }),
    sortDirection: Ember.computed('sorts', function () {
      return (this.sorts || "").split(' ')[1] || "desc";
    }),
    defaultSorts: "created_at desc",
    clearPageObserver: Ember.observer('assemblyShop', function () {
      this.set('page', 1);
    }),
    plannedCompletionDateObserver: Ember.observer('plannedCompletionDate', function () {
      if (this.plannedCompletionDate) {
        this.set('page', 1);
        this.set('plannedCompletionDateFilter', this.plannedCompletionDate);
      } else {
        this.set('plannedCompletionDateFilter', null);
      }
    }),
    actualCompletionDateObserver: Ember.observer('actualCompletionDate', function () {
      if (this.actualCompletionDate) {
        this.set('page', 1);
        this.set('actualCompletionDateFilter', this.actualCompletionDate);
      } else {
        this.set('actualCompletionDateFilter', null);
      }
    }),
    idFilterObserver: Ember.observer('idFilter', function () {
      this.set('page', 1);
    }),
    typeFilterObserver: Ember.observer('typeFilter', function () {
      this.set('page', 1);
    }),
    sortsObserver: Ember.observer('sortColumn', 'sortDirection', function () {
      this.set('sorts', "".concat(this.sortColumn, " ").concat(this.sortDirection));
    }),
    // sortsComputed: computed('sortColumn', 'sortDirection', function () {
    //   return `${this.sortColumn} ${this.sortDirection}`;
    // }),
    //
    // sortsObserver: observer('sortsComputed', function () {
    //   this.set('sorts', `${this.sortsComputed}`);
    // }),
    actions: {
      refresh: function refresh() {
        this.send("refreshRoute");
      },
      dragEnd: function dragEnd() {
        this.get('inProgress').forEach(function (item, index) {
          item.set('position', index);
          item.save();
        });
      },
      openCommentModal: function openCommentModal(comment) {
        this.set('modalOpened', true);
        this.set('selectedComment', comment);
      }
    }
  });

  _exports.default = _default;
});