define("zvo-frontend/models/order/product/production-stage/task", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    AASM_STATES: {
      'active': 'В работе',
      'completed': 'Завершена',
      'waiting': 'Ожидает выполнения'
    },
    AASM_STATE_CLASSES: {
      'active': 'badge-warning',
      'completed': 'badge-success',
      'waiting': 'badge-danger'
    },
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    position: _emberData.default.attr('number'),
    cost: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    required: _emberData.default.attr('boolean'),
    collapsed: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    aasmState: _emberData.default.attr('string'),
    isCanBeLogged: _emberData.default.attr('boolean'),
    orderId: _emberData.default.attr('string'),
    items: _emberData.default.hasMany('order/product/production-stage/task/item'),
    attachments: _emberData.default.hasMany('order/product/production-stage/task/attachment'),
    productionStage: _emberData.default.belongsTo('order/product/production-stage'),
    costObserver: Ember.observer('items.length', function () {
      if (this.get('items').length > 0) {
        this.set('cost', 0);
      }
    }),
    formattedAasmState: Ember.computed('aasmState', function () {
      return this.AASM_STATES[this.aasmState];
    }),
    aasmStateClass: Ember.computed('aasmState', function () {
      return this.AASM_STATE_CLASSES[this.aasmState];
    }),
    isHasIncompleteItems: Ember.computed('items.@each.completedCount', function () {
      var items = this.items;
      var isHas = false;
      items.forEach(function (item) {
        if (item.completedCount !== item.count) {
          isHas = true;
        }
      });
      return isHas;
    })
  });

  _exports.default = _default;
});