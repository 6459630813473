define("zvo-frontend/components/task-item-pusher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['mb-2'],
    isIncluded: Ember.computed('items.@each', function () {
      return this.items && this.items.findBy('baseId', this.item.baseId) !== undefined;
    }),
    actions: {
      addItem: function addItem(item) {
        this.addItem(item);
      },
      removeItem: function removeItem(item) {
        this.removeItem(item);
      }
    }
  });

  _exports.default = _default;
});