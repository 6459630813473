define("zvo-frontend/templates/root/users/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TUtkt55/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-6 offset-md-3 col-12\"],[9],[0,\"\\n    \"],[7,\"nav\"],[11,\"class\",\"nav nav-pills nav-fill\"],[9],[0,\"\\n      \"],[4,\"link-to\",[\"root.users.show.index\"],[[\"class\"],[\"nav-item nav-link\"]],{\"statements\":[[0,\"Профиль\"]],\"parameters\":[]},null],[0,\"\\n      \"],[4,\"link-to\",[\"root.users.show.logs\"],[[\"class\"],[\"nav-item nav-link\"]],{\"statements\":[[0,\"Отчеты\"]],\"parameters\":[]},null],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-12\"],[9],[0,\"\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zvo-frontend/templates/root/users/show.hbs"
    }
  });

  _exports.default = _default;
});