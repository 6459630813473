define("zvo-frontend/routes/root/products/show/template/show/tasks/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    title: 'Редактирование задачи',
    init: function init() {
      this._super();

      this.breadCrumb = {
        title: this.title
      };
    },
    model: function model(params) {
      var template = this.modelFor('root.products.show.template.show');
      return template.tasks.findBy('id', params.task_id);
    },
    setupController: function setupController(controller, model) {
      controller.set('task', model);
      this.store.findAll('attachment').then(function (att) {
        return controller.set('attachments', att);
      });
      var product = this.modelFor('root.products.show');
      controller.set('template', product.get('template').content);
      controller.set('product', product);
      controller.set('title', this.title);
    }
  });

  _exports.default = _default;
});