define("zvo-frontend/models/order/product/item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    cost: _emberData.default.attr('number'),
    count: _emberData.default.attr('number'),
    weight: _emberData.default.attr('number'),
    baseId: _emberData.default.attr('string'),
    product: _emberData.default.belongsTo('product'),
    attachments: _emberData.default.hasMany('order/product/item/attachment', {
      async: false
    })
  });

  _exports.default = _default;
});