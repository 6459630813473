define("zvo-frontend/routes/root/users/show/logs", ["exports", "moment", "ember-cli-pagination/remote/route-mixin"], function (_exports, _moment, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, {
    breadCrumb: {
      title: 'Отчеты'
    },
    perPage: 10,
    queryParams: {
      from: {
        refreshModel: true
      },
      to: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var q = {};
      var user = this.modelFor('root.users.show');

      if (Ember.isPresent(params.from)) {
        q['created_at_gteq'] = params.from;
        delete params['from'];
      }

      if (Ember.isPresent(params.to)) {
        q['created_at_lteq'] = params.to;
        delete params['to'];
      }

      q['user_id_eq'] = user.id;
      params['q'] = q;
      return Ember.RSVP.hash({
        logs: this.findPaged('logs/work', params),
        totalCount: this.ajax.request("/api/logs/works/get_total_count?user_id=".concat(q['user_id_eq'], "&from=").concat(q['created_at_gteq'], "&to=").concat(q['created_at_lteq'])).then(function (data) {
          return data.count;
        })
      });
    },
    setupController: function setupController(controller, model, transition) {
      controller.set('model', model.logs);
      controller.set('totalCost', model.totalCount);

      if (transition.queryParams.from) {
        controller.set('fromDate', (0, _moment.default)(transition.queryParams.from, "DD.MM.YYYY").startOf('day').toDate());
      }

      if (transition.queryParams.to) {
        controller.set('toDate', (0, _moment.default)(transition.queryParams.to, "DD.MM.YYYY").endOf('day').toDate());
      }
    }
  });

  _exports.default = _default;
});