define("zvo-frontend/authenticators/jwt", ["exports", "ember-simple-auth/authenticators/base", "zvo-frontend/config/environment"], function (_exports, _base, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    ajax: Ember.inject.service(),
    tokenEndpoint: "".concat(_environment.default.protocol, "://").concat(_environment.default.host, "/api/auth_token"),
    restore: function restore(data) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!Ember.isEmpty(data.token)) {
          resolve(data);
        } else {
          reject();
        }
      });
    },
    authenticate: function authenticate(creds) {
      var _this = this;

      var login = creds.login,
          password = creds.password;
      var ajax = this.get('ajax');
      var data = {
        auth: {
          login: login,
          password: password
        }
      };
      return new Ember.RSVP.Promise(function (resolve, reject) {
        ajax.request(_this.tokenEndpoint, {
          method: 'POST',
          data: data
        }).then(function (response) {
          var jwt = response.jwt; // Wrapping aync operation in Ember.run

          Ember.run(function () {
            resolve({
              token: jwt
            });
          });
        }, function (error) {
          // Wrapping aync operation in Ember.run
          Ember.run(function () {
            reject(error);
          });
        });
      });
    },
    invalidate: function invalidate(data) {
      return Ember.RSVP.Promise.resolve(data);
    }
  });

  _exports.default = _default;
});