define("zvo-frontend/helpers/inc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.inc = inc;
  _exports.default = void 0;

  function inc(params
  /*, hash*/
  ) {
    return parseInt(params[0]) + parseInt(params[1]);
  }

  var _default = Ember.Helper.helper(inc);

  _exports.default = _default;
});