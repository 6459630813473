define("zvo-frontend/routes/root/clients/individual/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    init: function init() {
      this._super();

      this.breadCrumb = {};
    },
    model: function model(params) {
      return this.store.findRecord('client/individual', params.id);
    },
    afterModel: function afterModel(model) {
      var name = "";

      if (model.secondName) {
        name += model.secondName + " ";
      }

      if (model.firstName) {
        name += model.firstName + " ";
      }

      if (model.thirdName) {
        name += model.thirdName;
      }

      var client = {
        title: name
      };
      this.set('breadCrumb', client);
    }
  });

  _exports.default = _default;
});