define("zvo-frontend/templates/root/products/show/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XbgZ9o8r",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-12\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[1,[23,[\"model\",\"name\"]],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"canBeDeletedFromUi\"]]],null,{\"statements\":[[0,\"      \"],[7,\"button\"],[11,\"class\",\"btn btn-danger\"],[3,\"action\",[[22,0,[]],[27,\"perform\",[[23,[\"destroy\"]]],null]]],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"fa fa-trash\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n        Удалить\\n      \"],[10],[0,\"\\n      \"],[4,\"link-to\",[\"root.products.edit\",[23,[\"model\",\"id\"]]],[[\"class\"],[\"btn btn-secondary\"]],{\"statements\":[[7,\"i\"],[11,\"class\",\"fa fa-pencil\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\" Редактировать\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-12 mt-2\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"template\"]]],null,{\"statements\":[[4,\"link-to\",[\"root.products.show.template.show\"],[[\"class\"],[\"btn btn-primary\"]],{\"statements\":[[0,\"        Шаблон\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"can\",[\"createTemplate product\"],null]],null,{\"statements\":[[0,\"        \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[3,\"action\",[[22,0,[]],[27,\"perform\",[[23,[\"createTemplate\"]]],null]]],[9],[0,\"\\n          \"],[7,\"i\"],[11,\"class\",\"fa fa-plus\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n          Добавить шаблон\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zvo-frontend/templates/root/products/show/index.hbs"
    }
  });

  _exports.default = _default;
});